<template>
  <v-form ref="orderSlotForm" v-model="isValid" lazy-validation @change="validate()">
    <v-row>
      <v-col cols="12" sm="6" md="2">
        {{ $t('order.form.editOrderDetail.input.preparationDate.label') }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select v-model="preparationDate" :input-value="preparationDate" :items="preparationSlots" item-value="date" item-text="text"
                  :loading="loading" :disabled="loading" required dense hide-details style="width:95%" @change="updatePreparationDate()"
        />
      </v-col>
      <v-col cols="12" sm="6" md="2">
        {{ targetDateLabel }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select v-model="targetDate" :input-value="targetDate" :items="targetSlots" item-value="date" item-text="text"
                  :loading="loading" :disabled="loading" required dense hide-details style="width:95%" @change="updateTargetDate()"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';

  export default {
    name: 'OrderSlotForm',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isValid: true,
        loading: false,
        order: { actions: { preparation: {}, delivery: {} } },
        preparationDate: undefined,
        previousPreparationDate: undefined,
        targetDateLabel: undefined,
        targetDate: undefined,
        previousTargetDate: undefined,
        preparationSlots: [],
        targetSlots: []
      };
    },
    watch: {
      value: function () {
        this.order = this.value;

        this.initOrder();
      },
      latitude: function () {
        this.order.actions[this.actionType].latitude = this.latitude;
      },
      longitude: function () {
        this.order.actions[this.actionType].longitude = this.longitude;
      }
    },
    mounted: async function () {
      this.order = this.value;

      this.initOrder();
      await this.getSlots();
    },
    methods: {
      initOrder () {
        this.preparationDate = DateTime.fromISO(this.order.actions?.preparation?.date).toUTC().toISO();
        this.previousPreparationDate = this.preparationDate;

        if (this.order.actions.delivery !== undefined) {
          this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.delivery');
          this.targetDate = DateTime.fromISO(this.order.actions.delivery.date).toUTC().toISO();
        } else if (this.order.actions.onsite !== undefined) {
          this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.onsite');
          this.targetDate = DateTime.fromISO(this.order.actions.onsite.date).toUTC().toISO();
        } else if (this.order.actions.pickup !== undefined) {
          this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.pickup');
          this.targetDate = DateTime.fromISO(this.order.actions.pickup.date).toUTC().toISO();
        } else if (this.order.actions.shipping !== undefined) {
          this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.shipping');
          this.targetDate = DateTime.fromISO(this.order.actions.shipping.date).toUTC().toISO();
        }
        this.previousTargetDate = this.targetDate;
      },
      updatePreparationDate () {
        if (this.preparationDate !== undefined && this.previousPreparationDate !== undefined && this.preparationDate !== this.previousPreparationDate) {
          const diff = DateTime.fromISO(this.preparationDate).diff(DateTime.fromISO(this.previousPreparationDate));
          this.targetDate = DateTime.fromISO(this.targetDate).plus({ minutes: diff.toFormat('m') }).toUTC().toISO();
        }
        this.previousPreparationDate = this.preparationDate;
      },
      updateTargetDate () {
        if (this.targetDate !== undefined && this.previousTargetDate !== undefined && this.targetDate !== this.previousTargetDate) {
          const diff = DateTime.fromISO(this.targetDate).diff(DateTime.fromISO(this.previousTargetDate));
          this.preparationDate = DateTime.fromISO(this.preparationDate).plus({ minutes: diff.toFormat('m') }).toUTC().toISO();
        }
        this.previousTargetDate = this.targetDate;
      },
      async getSlots () {
        if (this.preparationSlots.length === 0 && this.targetSlots.length === 0) {
          this.loading = true;
          try {
            const filter = this.order.location?.key === undefined ? '' : '?location=' + this.order.location?.key;
            const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'slots' + filter);
            if (response.data !== undefined && response.data.preparation !== undefined && Array.isArray(response.data.preparation)
              && response.data.target !== undefined && Array.isArray(response.data.target)) {
              this.preparationSlots = response.data.preparation;
              this.targetSlots = response.data.target;
            }
          } catch (error) {
            await handleRequestError(error, this.$router);
          }
          this.loading = false;
        }
      },
      validate () {
        this.isValid = this.$refs.orderActionForm.validate();
        if (this.isValid) {
          this.$emit('input', this.order);
        }

        this.$emit('validate', this.isValid);

        return this.isValid;
      }
    }
  };
</script>
