<template>
  <v-form ref="orderCustomerForm" v-model="isValid" lazy-validation @keyup.native="validate()">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="order.customer.firstName" :counter="firstNameMaxLength" required :rules="firstNameRules"
                      :label="$t('order.form.editOrderCustomer.input.firstName.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="order.customer.lastName" :counter="lastNameMaxLength" required :rules="lastNameRules"
                      :label="$t('order.form.editOrderCustomer.input.lastName.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="order.customer.company" :counter="companyMaxLength" :rules="companyRules" :label="$t('order.form.editOrderCustomer.input.company.label')" :clearable="true" />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="order.customer.phone" :rules="phoneRules" :label="$t('order.form.editOrderCustomer.input.phone.label')" :clearable="true" />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="order.customer.phone2" :rules="phone2Rules" :label="$t('order.form.editOrderCustomer.input.phone2.label')" :clearable="true" />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="order.customer.email" :counter="emailMaxLength" :rules="emailRules" :label="$t('order.form.editOrderCustomer.input.email.label')" :clearable="true" />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox v-model="order.customer.acceptCallMarketing" :label="$t('order.form.editOrderCustomer.input.acceptCallMarketing.label')" color="accent" />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox v-model="order.customer.acceptSmsMarketing" :label="$t('order.form.editOrderCustomer.input.acceptSmsMarketing.label')" color="accent" />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox v-model="order.customer.acceptEmailMarketing" :label="$t('order.form.editOrderCustomer.input.acceptEmailMarketing.label')" color="accent" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { isValidEmail, isValidPhoneNumber } from '@/util/ValidationUtil';

  export default {
    name: 'OrderCustomerForm',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isValid: true,
        order: { customer: {} },
        firstNameMinLength: 1,
        firstNameMaxLength: 30,
        firstNameRules: [
          v => !v || (v && v.length >= this.firstNameMinLength) || this.$t('order.form.editOrderCustomer.input.firstName.rule.minLength', { count: this.firstNameMinLength }),
          v => !v || (v && v.length <= this.firstNameMaxLength) || this.$t('order.form.editOrderCustomer.input.firstName.rule.maxLength', { count: this.firstNameMaxLength })
        ],
        lastNameMinLength: 1,
        lastNameMaxLength: 30,
        lastNameRules: [
          v => !v || (v && v.length >= this.lastNameMinLength) || this.$t('order.form.editOrderCustomer.input.lastName.rule.minLength', { count: this.lastNameMinLength }),
          v => !v || (v && v.length <= this.lastNameMaxLength) || this.$t('order.form.editOrderCustomer.input.lastName.rule.maxLength', { count: this.lastNameMaxLength })
        ],
        companyMinLength: 1,
        companyMaxLength: 80,
        companyRules: [
          v => !v || (v && v.length >= this.companyMinLength) || this.$t('order.form.editOrderCustomer.input.company.rule.minLength', { count: this.companyMinLength }),
          v => !v || (v && v.length <= this.companyMaxLength) || this.$t('order.form.editOrderCustomer.input.company.rule.maxLength', { count: this.companyMaxLength })
        ],
        phoneMinLength: 10,
        phoneMaxLength: 30,
        phoneRules: [
          v => !v || (v && v.length >= this.phoneMinLength) || this.$t('order.form.editOrderCustomer.input.phone.rule.minLength', { count: this.phoneMinLength }),
          v => !v || (v && v.length <= this.phoneMaxLength) || this.$t('order.form.editOrderCustomer.input.phone.rule.maxLength', { count: this.phoneMaxLength }),
          v => !v || (v && isValidPhoneNumber(v)) || this.$t('order.form.editOrderCustomer.input.phone.rule.valid')
        ],
        phone2MinLength: 10,
        phone2MaxLength: 30,
        phone2Rules: [
          v => !v || (v && v.length >= this.phone2MinLength) || this.$t('order.form.editOrderCustomer.input.phone2.rule.minLength', { count: this.phone2MinLength }),
          v => !v || (v && v.length <= this.phone2MaxLength) || this.$t('order.form.editOrderCustomer.input.phone2.rule.maxLength', { count: this.phone2MaxLength }),
          v => !v || (v && isValidPhoneNumber(v)) || this.$t('order.form.editOrderCustomer.input.phone2.rule.valid')
        ],
        emailMinLength: 5,
        emailMaxLength: 350,
        emailRules: [
          v => !v || (v && v.length >= this.emailMinLength) || this.$t('order.form.editOrderCustomer.input.email.rule.minLength', { count: this.emailMinLength }),
          v => !v || (v && v.length <= this.emailMaxLength) || this.$t('order.form.editOrderCustomer.input.email.rule.maxLength', { count: this.emailMaxLength }),
          v => !v || (v && isValidEmail(v)) || this.$t('order.form.editOrderCustomer.input.email.rule.valid')
        ]
      };
    },
    watch: {
      value: function () {
        this.order = this.value;
      }
    },
    mounted: function () {
      this.order = this.value;
    },
    methods: {
      validate () {
        this.isValid = this.$refs.orderCustomerForm.validate();
        if (this.isValid) {
          this.$emit('input', this.order);
        }

        this.$emit('validate', this.isValid);

        return this.isValid;
      }
    }
  };
</script>
