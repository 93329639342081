<template>
  <v-form ref="OrderProductForm" v-model="isValid" lazy-validation @keyup.native="validate()" @change="validate()">
    <v-row v-for="(orderProduct, op) in order.products" :key="op">
      <v-col cols="2">
        <v-select
          v-model="orderProduct.quantity"
          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
          :label="$t('orderEditor.section.product.input.quantity')"
          class="ma-auto"
          style="width:50%"
        />
      </v-col>
      <v-col cols="8">
        <v-select
          v-model="orderProduct.id"
          :items="products"
          :item-disabled="disableCategory"
          :label="$t('orderEditor.section.product.input.name')"
          :loading="loading"
          :disabled="loading"
          class="ma-auto"
        />
      </v-col>
      <v-col cols="2" class="text-center">
        <v-icon class="ma-auto" color="red" medium :title="$t('orderEditor.section.product.button.remove')" @click="removeProduct(orderProduct)">
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn color="secondary" @click.stop="addProduct()">
          <v-icon class="pr-2">
            mdi-plus
          </v-icon>
          {{ $t('orderEditor.section.product.button.add') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';

  export default {
    name: 'OrderProductForm',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isValid: true,
        loading: false,
        products: [],
        order: { products: [] }
      };
    },
    mounted: async function () {
      this.order = this.value;

      await this.getProductNames();
    },
    methods: {
      addProduct () {
        if (this.order.products.find((orderProduct) => orderProduct.id === undefined) !== undefined) {
          return;
        }

        this.order.products.push({ id: undefined, quantity: 1 });
      },
      removeProduct (product) {
        const index = this.order.products.findIndex((p) => p.id === product.id);
        if (index >= 0) {
          this.order.products.splice(index, 1);
        }
      },
      disableCategory (item) {
        return item.value === undefined;
      },
      async getProductNames () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'products/names');
          if (response.data !== undefined && Array.isArray(response.data.products)) {
            const productsByCategory = {};
            response.data.products.forEach((product) => {
              product.categories.forEach((category) => {
                if (productsByCategory[category.reference] === undefined) {
                  productsByCategory[category.reference] = {
                    category: category,
                    products: [product]
                  };
                } else {
                  productsByCategory[category.reference].products.push(product);
                }
              });
            });

            this.products = [];
            Object.keys(productsByCategory).sort().forEach((ref) => {
              this.products.push({ value: undefined, text: productsByCategory[ref].category.name.toUpperCase() });
              productsByCategory[ref].products.forEach((product) => {
                const price = product.prices.find((price) => price.type === 'sell' && price.action === 'delivery' && price.provider === 'shopify');
                this.products.push({ value: product.id, text: product.name + (price === undefined ? '' : ' (' + String(price.amount) + '€)') });
              });
            });
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
        this.loading = false;
      },
      validate () {
        this.isValid = this.$refs.OrderProductForm.validate();
        if (this.isValid) {
          this.$emit('input', this.order);
        }

        this.$emit('validate', this.isValid);

        return this.isValid;
      }
    }
  };
</script>
