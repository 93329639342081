<template>
  <v-form ref="orderActionForm" v-model="isValid" lazy-validation @keyup.native="validate()" @change="validate()">
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn-toggle v-model="actionType" borderless dark dense mandatory>
          <v-btn medium value="delivery">
            {{ $t('order.form.editOrderCustomer.input.actionType.delivery') }}
          </v-btn>
          <v-btn medium value="pickup">
            {{ $t('order.form.editOrderCustomer.input.actionType.pickup') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field v-model="address1" :counter="address1MaxLength" required :rules="address1Rules"
                      :label="$t('order.form.editOrderCustomer.input.address1.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field v-model="address2" :counter="address2MaxLength" required :rules="address2Rules"
                      :label="$t('order.form.editOrderCustomer.input.address2.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="zip" :counter="zipMaxLength" required :rules="zipRules"
                      :label="$t('order.form.editOrderCustomer.input.zip.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="city" :counter="cityMaxLength" required :rules="cityRules"
                      :label="$t('order.form.editOrderCustomer.input.city.label')" :clearable="true"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="country" :counter="countryMaxLength" required :rules="countryRules"
                      :label="$t('order.form.editOrderCustomer.input.country.label')" :clearable="true"
        />
      </v-col>
      <v-col v-if="latitude && longitude" cols="12" class="text-center">
        <span class="px-2">
          {{ $t('order.form.editOrderCustomer.input.latitude.label') }}
          {{ latitude }}
        </span>
        |
        <span class="px-2">
          {{ $t('order.form.editOrderCustomer.input.longitude.label') }}
          {{ longitude }}
        </span>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  export default {
    name: 'OrderActionForm',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isValid: true,
        order: { actions: { preparation: {}, delivery: {} } },
        actionType: 'delivery',
        address1: '',
        address1MinLength: 1,
        address1MaxLength: 200,
        address1Rules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.address1.rule.required'),
          v => (v && v.length >= this.address1MinLength) || this.$t('order.form.editOrderCustomer.input.address1.rule.minLength', { count: this.address1MinLength }),
          v => (v && v.length <= this.address1MaxLength) || this.$t('order.form.editOrderCustomer.input.address1.rule.maxLength', { count: this.address1MaxLength })
        ],
        address2: '',
        address2MinLength: 1,
        address2MaxLength: 200,
        address2Rules: [
          v => !v || (v && v.length >= this.address2MinLength) || this.$t('order.form.editOrderCustomer.input.address2.rule.minLength', { count: this.address2MinLength }),
          v => !v || (v && v.length <= this.address2MaxLength) || this.$t('order.form.editOrderCustomer.input.address2.rule.maxLength', { count: this.address2MaxLength })
        ],
        zip: '',
        zipMinLength: 1,
        zipMaxLength: 10,
        zipRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.zip.rule.required'),
          v => (v && v.length >= this.zipMinLength) || this.$t('order.form.editOrderCustomer.input.zip.rule.minLength', { count: this.zipMinLength }),
          v => (v && v.length <= this.zipMaxLength) || this.$t('order.form.editOrderCustomer.input.zip.rule.maxLength', { count: this.zipMaxLength })
        ],
        city: '',
        cityMinLength: 1,
        cityMaxLength: 50,
        cityRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.city.rule.required'),
          v => (v && v.length >= this.cityMinLength) || this.$t('order.form.editOrderCustomer.input.city.rule.minLength', { count: this.cityMinLength }),
          v => (v && v.length <= this.cityMaxLength) || this.$t('order.form.editOrderCustomer.input.city.rule.maxLength', { count: this.cityMaxLength })
        ],
        country: 'France',
        countryMinLength: 1,
        countryMaxLength: 50,
        countryRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.country.rule.required'),
          v => (v && v.length >= this.countryMinLength) || this.$t('order.form.editOrderCustomer.input.country.rule.minLength', { count: this.countryMinLength }),
          v => (v && v.length <= this.countryMaxLength) || this.$t('order.form.editOrderCustomer.input.country.rule.maxLength', { count: this.countryMaxLength })
        ],
        latitude: undefined,
        longitude: undefined
      };
    },
    watch: {
      value: function () {
        this.order = this.value;
        if (this.order.actions === undefined) {
          this.order.actions = { preparation: {}, delivery: {} };
        }

        this.initOrder();
      },
      actionType: function () {
        if (this.order.actions.pickup !== undefined) {
          this.order.actions[this.actionType] = this.order.actions.pickup;
        } else if (this.order.actions.delivery !== undefined) {
          this.order.actions[this.actionType] = this.order.actions.delivery;
        }
      },
      address1: function () {
        this.order.actions[this.actionType].address1 = this.address1;
      },
      address2: function () {
        this.order.actions[this.actionType].address2 = this.address2;
      },
      zip: function () {
        this.order.actions[this.actionType].zip = this.zip;
      },
      city: function () {
        this.order.actions[this.actionType].city = this.city;
      },
      country: function () {
        this.order.actions[this.actionType].country = this.country;
      },
      latitude: function () {
        this.order.actions[this.actionType].latitude = this.latitude;
      },
      longitude: function () {
        this.order.actions[this.actionType].longitude = this.longitude;
      }
    },
    mounted: function () {
      this.order = this.value;

      this.initOrder();
    },
    methods: {
      initOrder () {
        this.actionType = this.order.actions.delivery !== undefined ? 'delivery' : (this.order.actions.pickup !== undefined ? 'pickup' : null);
        this.address1 = this.order.actions.delivery?.address?.address1 || this.order.actions.pickup?.address?.address1 || '';
        this.address2 = this.order.actions.delivery?.address?.address2 || this.order.actions.pickup?.address?.address2 || '';
        this.zip = this.order.actions.delivery?.address?.zip || this.order.actions.pickup?.address?.zip || '';
        this.city = this.order.actions.delivery?.address?.city || this.order.actions.pickup?.address?.city || '';
        this.country = this.order.actions.delivery?.address?.country || this.order.actions.pickup?.address?.country || 'France';
        this.latitude = this.order.actions.delivery?.address?.latitude || this.order.actions.pickup?.address?.latitude;
        this.longitude = this.order.actions.delivery?.address?.longitude || this.order.actions.pickup?.address?.longitude;
      },
      validate () {
        this.isValid = this.$refs.orderActionForm.validate();
        if (this.isValid) {
          this.$emit('input', this.order);
        }

        this.$emit('validate', this.isValid);

        return this.isValid;
      }
    }
  };
</script>
