export const isValidEmail = (data) => {
  if (typeof data !== 'string') {
    return false;
  }

  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

  return re.test(data);
};

export const isValidPhoneNumber = (data) => {
  if (typeof data !== 'string') {
    return false;
  }

  const nospace = data.replace(/\s/g, '');
  // const re = /^([\\+\s0-9]+(#[0-9]+)?)$/; // previous regex

  return /^\+[0-9]+(#[0-9]+)?$/.test(nospace) || /^0[0-9]{9}(#[0-9]+)?$/.test(nospace);
};
