<template>
  <v-container id="order-editor" fluid tag="section" class="ma-4 pa-8 grey rounded-lg">
    <alert-notification :message="alert" />
    <h1 v-if="order.id">
      {{ $t('orderEditor.title.modify') }}
    </h1>
    <h1 v-else>
      {{ $t('orderEditor.title.new') }}
    </h1>
    <br>
    <v-card color="write" class="main-card">
      <v-expansion-panels v-model="panel" :accordion="true" :multiple="true" :focusable="true" :flat="true" :hover="true" :tile="true">
        <v-expansion-panel>
          <v-expansion-panel-header :ripple="true" color="quaternary" class="text-uppercase">
            {{ $t('orderEditor.section.address.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :eager="true">
            <order-action-form v-model="order" @validate="validateActions()" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header :ripple="true" color="quaternary" class="text-uppercase">
            {{ $t('orderEditor.section.slot.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :eager="true">
            <order-slot-form v-model="order" @validate="validateCustomer()" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header :ripple="true" color="quaternary" class="text-uppercase">
            {{ $t('orderEditor.section.customer.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :eager="true">
            <order-customer-form v-model="order" @validate="validateCustomer()" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header :ripple="true" color="quaternary" class="text-uppercase">
            {{ $t('orderEditor.section.product.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :eager="true">
            <order-product-form v-model="order" @validate="validateActions()" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-spacer />
        <v-form ref="orderCustomerForm" v-model="isValid" lazy-validation>
          <v-btn :disabled="!isValid || loading" :loading="loading" color="success" @click.stop="validate() && editOrderCustomer()">
            <span v-if="order.id">{{ $t('orderEditor.button.update') }}</span>
            <span v-else>{{ $t('orderEditor.button.create') }}</span>
          </v-btn>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import OrderActionForm from '@/views/forms/order/OrderActionForm';
  import OrderSlotForm from '@/views/forms/order/OrderSlotForm';
  import OrderCustomerForm from '@/views/forms/order/OrderCustomerForm';
  import OrderProductForm from '@/views/forms/order/OrderProductForm';

  export default {
    name: 'OrderEditor',
    components: { AlertNotification, OrderActionForm, OrderSlotForm, OrderCustomerForm, OrderProductForm },
    data () {
      return {
        alert: '',
        panel: [0, 1, 2, 3],
        loading: false,
        isValid: true,
        order: {
          actions: {},
          customer: {
            firstName: '',
            lastName: '',
            company: '',
            phone: '',
            phone2: '',
            email: '',
            acceptCallMarketing: true,
            acceptSmsMarketing: true,
            acceptEmailMarketing: true
          },
          products: [{ name: 'toto', quantity: 1 }]
        }
      };
    },
    mounted: async function () {
      await this.getOrder();
    },
    methods: {
      async getOrder () {
        this.loading = true;
        try {
          const orderId = this.$route.query.id;
          if (orderId !== undefined) {
            const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'order?id=' + String(orderId));
            if (response.data !== undefined && typeof response.data.order === 'object') {
              this.order = response.data.order;
            }
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
        this.loading = false;
      },
      validate () {
        return this.isValid;
      },
      validateActions (isValid) {
        this.isValid = this.isValid && isValid;
      },
      validateCustomer (isValid) {
        this.isValid = this.isValid && isValid;
      }
    }
  };
</script>
